import * as React from "react";
import Images from "../../images";
import { Button } from "../Button/Button";
import styles from "./Index.module.css";

export interface ISelectItemProps {
   value: string;
   iconName: "mitsubishi" | "mazda" | "honda" | "chevrolet";
   description?: string;
   increase: () => void;
   decrease: () => void;
}

export const SelectItem: React.FC<ISelectItemProps> = ({
   value,
   iconName,
   description,
   increase,
   decrease,
}) => {
   return (
      <div className={styles["wrap"]}>
         <div className={styles.content}>
            <button onClick={decrease}>-</button>
            <img src={Images[iconName]} alt={iconName} />
            <span className={styles.value}>{value}</span>
            <button
               className={`${styles["btn"]} ${styles["btn-add"]}`}
               onClick={increase}
            >
               +
            </button>
         </div>
         <div className={styles.small}>{description}</div>
      </div>
   );
};

export const ItemSelectList = ({
   submitHandler,
}: {
   submitHandler: (amount: string) => void;
}) => {
   const [milk, setMilk] = React.useState(0);
   const [cocoa, setCocoa] = React.useState(0);
   const [nut, setNut] = React.useState(0);
   const [soya, setSoya] = React.useState(0);
   return (
      <div className={`${styles["card-wrapper"]}`}>
         <SelectItem
            value={(milk / 10).toFixed(1)}
            iconName="mitsubishi"
            description="ECLIPSE 1995: 0.1 bnb"
            increase={() => setMilk((m) => m + 1)}
            decrease={() => setMilk((m) => (m === 0 ? 0 : m - 1))}
         />
         <SelectItem
            value={(nut / 10).toFixed(1)}
            iconName="mazda"
            description="RX-7: 0.5 bnb"
            increase={() => setNut((m) => m + 5)}
            decrease={() => setNut((m) => (m === 0 ? 0 : m - 5))}
         />
         <SelectItem
            value={(soya / 10).toFixed(1)}
            iconName="honda"
            description="S2000: 1 bnb"
            increase={() => setSoya((m) => m + 10)}
            decrease={() => setSoya((m) => (m === 0 ? 0 : m - 10))}
         />
         <SelectItem
            value={(cocoa / 10).toFixed(1)}
            iconName="chevrolet"
            description="Camaro: 5 bnb"
            increase={() => setCocoa((m) => m + 50)}
            decrease={() => setCocoa((m) => (m === 0 ? 0 : m - 50))}
         />
         <div className={styles.total}>
            {((milk + cocoa + soya + nut) / 10).toFixed(1)}{" "}
            <span className={styles.bnb}>BNB</span>
         </div>
         <div className={styles.tools}>
            <Button
               onClick={() =>
                  submitHandler(((milk + cocoa + soya + nut) / 10).toFixed(2))
               }
               bgColor="green"
            >
               Buy
            </Button>
         </div>
      </div>
   );
};
