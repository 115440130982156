import * as React from "react";
import { useState } from "react";
import Modal from "react-responsive-modal";
import { buyEggs, calculateEggBuy } from "../../utils/contract";
import { getRef } from "../../utils/env";
import { Button } from "../Button/Button";
import Input from "../Input/Input";
import { ItemSelectList } from "../ItemSelect/Index";
import Label from "../Label/Label";
import styles from "./ContractView.module.css";

export interface IContractViewProps {
   totalInvestment: string;
   totalUsers: string;
   registered: boolean;
}

export const ContractView: React.FC<IContractViewProps> = (props) => {
   const [amount, setAmount] = useState("");
   const [label, setLabel] = useState("");
   const [showModal, setShowModal] = useState(false);

   function handleBuy(amount: string) {
      if (!getRef()) {
         alert("Please use an invitation link");
         return;
      }
      buyEggs(getRef(), amount, () => {});
      setShowModal(false);
   }

   React.useEffect(() => {
      async function run() {
         const val = await calculateEggBuy(amount);
         setLabel(val.div(1000000).toNumber());
      }
      if (amount && parseFloat(amount) > 0) run();
      else setLabel("");
   }, [amount]);

   return (
      <div className={`box ${styles.wrap}`}>
         {/* <li>{props.userMiners}</li> */}
         {/* <Label
            label="Contract Balance"
            value={props.totalInvestment}
            unit="BNB"
         /> */}

         <div className={styles.tools}>
            <Button onClick={() => setShowModal(true)}>
               {props.registered ? "Buy Cars" : "Register"}
            </Button>
         </div>
         {/* <Label label="Total Users" value={props.totalUsers} /> */}
         <Modal open={showModal} onClose={() => setShowModal(false)}>
            {/* <p className="center">Buy Miners With BNB</p>
            <div className={styles.tools}>
               <Input onChange={setAmount} />
               {label && (
                  <p>
                     <b>{label}</b> Pirate Miners
                  </p>
               )}

               <Button
                  onClick={() => {
                     if (parseFloat(amount) < 0.1) {
                        alert("Minimum amount is 0.1 BNB");
                        return;
                     }
                     if (!getRef()) {
                        alert("Please use an invitation link");
                        return;
                     }
                     buyEggs(getRef(), amount);
                  }}
               >
                  Buy Miners
               </Button>
            </div> */}
            <ItemSelectList submitHandler={handleBuy} />
         </Modal>
         {/* <li>{props.userDividends}</li> */}
         {/* <li>{props.marketNewtella}</li>
            <li>{props.contractBalance}</li> */}
      </div>
   );
};
