export function timeParts(distance:number){
   const days = Math.floor(distance/DAY);
   const hours = Math.floor((distance % DAY) / HOUR);
   const minutes = Math.floor((distance % HOUR) / MINUTE);
   const seconds = Math.floor(distance % MINUTE);
   return [days, hours, minutes, seconds];
}

export function dailyTimeString(distance: number) {
   const [,hours, minutes, seconds] = timeParts(distance);
   return `${hours}:${minutes}:${seconds}`;
}

export const MINUTE = 60;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;

export function days(distance: number) {
   return Math.floor(distance / DAY);
}
