import styles from "./Header.module.css";
import Images from "../../images";
import { Button } from "../Button/Button";
import { useState } from "react";
import Modal from "react-responsive-modal";
import { contractAddress } from "../../utils/contract";

export function Header() {
   const [state, setState] = useState(false);
   return (
      <div className={styles.wrap}>
         <div className={styles.logo}>
            <img src={Images.logo} alt="Fast&Furious" />
         </div>
         <div className={styles.info}>
            Earn 5% Daily <span className={styles.unlimited}>Unlimited</span>
            <div className={styles.subTitle}>
               6+1 Strategy <a onClick={() => setState(true)}>learn more</a>
            </div>
            <div className={styles.buttons}>
               <Button href="https://docs.google.com/gview?embedded=true&url=https://fandfbnb.com/audit.pdf">
                  Audit
               </Button>
               <Button
                  href={`https://www.bscscan.com/address/${contractAddress}`}
               >
                  BSC
               </Button>
            </div>
         </div>

         <Modal open={state} onClose={() => setState(false)}>
            <h1>6 + 1 Strategy</h1>
            <p>
               This feature help investors to maximize their profits. With this
               strategy you can not withdraw for 6 days. You have only 1 day to
               withdraw every 6 days. Keep in mind obey this strategy to get the
               most profit.
            </p>
         </Modal>
      </div>
   );
}
