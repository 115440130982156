import Images from "../../images";
import { hatchEggs, sellEggs } from "../../utils/contract";
import { Button } from "../Button/Button";
import Label from "../Label/Label";
import styles from "./UserView.module.css";

function withdrawalAllowedDays(distance: number) {
   var days = Math.floor(distance / (60 * 60 * 24));
   return days >= 6 && days % 7 === 6;
}

function withdrawalAllowedHours(distance: number) {
   var days = Math.floor(distance / (60 * 60));
   return days % 7 === 6;
}

function timeString(distance: number) {
   let mainDistance = distance;
   const allowed = withdrawalAllowedDays(distance);
   if (allowed) {
      distance = 86400 - (distance % 86400);
   } else {
      distance = 86400 * 6 - (distance % (86400 * 7));
   }
   var days = Math.floor(distance / (60 * 60 * 24));
   var hours = Math.floor((distance % (60 * 60 * 24)) / (60 * 60));
   var minutes = Math.floor((distance % (60 * 60)) / 60);
   var seconds = Math.floor(distance % 60);

   return (
      <div className={allowed ? styles.allowed : styles.blocked}>
         {allowed ? (
            <img src={Images.green} alt="" />
         ) : (
            <img src={Images.red} alt="" />
         )}
         <div className={styles.timer}>
            {(days % 6) + "d " + hours + "h " + minutes + "m " + seconds + "s "}{" "}
            {/* <div className={styles.normal}>
               {!allowed ? "until 6 days timer pass" : "until 6 days timer start"}
            </div> */}
            {allowed ? timeStringHours(mainDistance) : null}
         </div>
      </div>
   );
}

function timeStringHours(distance: number) {
   const allowed = withdrawalAllowedHours(distance);
   if (allowed) {
      distance = 3600 - (distance % 3600);
   } else {
      distance = 3600 * 6 - (distance % (3600 * 7));
   }
   //var days = Math.floor(distance / (60 * 60 * 24));
   var hours = Math.floor((distance % (60 * 60 * 24)) / (60 * 60));
   var minutes = Math.floor((distance % (60 * 60)) / 60);
   var seconds = Math.floor(distance % 60);

   return (
      <div className={allowed ? styles.allowed : styles.blocked}>
         {allowed ? (
            <img src={Images.green} alt="" />
         ) : (
            <img src={Images.red} alt="" />
         )}
         <div className={styles.timer}>
            {hours + "h " + minutes + "m " + seconds + "s "}{" "}
            <div className={styles.normal}>
               {!allowed ? "until withdrawal open" : "until withdrawal close"}
            </div>
         </div>
      </div>
   );
}

export interface IUserViewProps {
   miners: string;
   grog: string;
   grogBNB: string;
   invested: string;
   withdrawal: string;
   checkpoint: string;
}

export const UserView = ({
   miners,
   grog,
   grogBNB,
   invested,
   withdrawal,
   checkpoint,
}: IUserViewProps) => {
   return (
      <div className={`${styles["card-wrapper"]}`}>
         <Label label="Miner Cars" value={miners} />
         <Label label="Your TVL" value={grogBNB} unit="BNB" />
         {/* <Label label="Total Invested" value={invested} />
         <Label label="Withdrawn" value={withdrawal} /> */}
         {/* <div className={styles.total}>{grogBNB}</div> */}
         {parseFloat(invested) > 0 && (
            <div>
               <div className={styles.timer}>
                  {timeString(
                     new Date().getTime() / 1000 - parseInt(checkpoint)
                  )}
               </div>
               <div className={styles.tools}>
                  {withdrawalAllowedDays(
                     new Date().getTime() / 1000 - parseInt(checkpoint)
                  ) &&
                  withdrawalAllowedHours(
                     new Date().getTime() / 1000 - parseInt(checkpoint)
                  ) ? (
                     <Button onClick={() => sellEggs()} bgColor="green">
                        DRIFT
                     </Button>
                  ) : null}
                  <Button
                     onClick={() => {
                        hatchEggs();
                     }}
                     bgColor="red"
                  >
                     SPRINT
                  </Button>
               </div>
            </div>
         )}
      </div>
   );
};
