import bsc from "./assets/bsc.png";
import bnb from "./assets/bnb.png";
import jotform from "./assets/jotform.png";
import logo from "./assets/logo.png";
import help from "./assets/help.png";
import linktee from "./assets/linktee.png";
import tel from "./assets/tel.png";
import chevrolet from "./assets/chevrolet.png";
import honda from "./assets/honda.png";
import mazda from "./assets/mazda.png";
import mitsubishi from "./assets/mitsubishi.png";
import nos from "./assets/nos.png";
import red from "./assets/red.png";
import green from "./assets/green.png";
import blinking from "./assets/blinking_new.gif";

const Images = {
   bsc,
   bnb,
   jotform,
   help,
   logo,
   linktee,
   tel,
   mitsubishi,
   mazda,
   honda,
   chevrolet,
   nos,
   red,
   green,
   blinking,
};

export default Images;
